import React from "react";
import LandingPage from "../components/LandingPage/LandingPage";
import { graphql } from "gatsby";

function LandingPageTemplate({ data }) {
  return <LandingPage data={data} />;
}

export const query = graphql`
  query($slug: String!) {
    allContentfulLandingPage(filter: { slug: { eq: $slug } }) {
      ...LandingPageData
    }
  }
`;

export default LandingPageTemplate;
